<template>
  <div class="w-full bottom-0 left-0 fixed p-4" v-if="$pwa?.offlineReady || ($pwa?.showInstallPrompt && !$pwa?.offlineReady)">
    <div
        v-if="$pwa?.offlineReady"
        class="shadow-md rounded border-2 border-yellow-500 bg-yellow-100 dark:bg-yellow-800 p-2 mx-auto w-full max-w-lg pr-12 relative leading-5 text-yellow-700 dark:text-yellow-300 font-medium"
        role="alert"
    >
      <div>
        <span v-if="$pwa.offlineReady">{{ t('INSTALL_OFFLINE_READY') }}</span>
      </div>
      <GMButton class="mt-4" @click="$pwa.cancelPrompt()">{{ t('CLOSE') }}</GMButton>
    </div>
    <div
        v-if="$pwa?.showInstallPrompt && !$pwa?.offlineReady"
        class="shadow-md rounded border-2 border-yellow-500 bg-yellow-100 dark:bg-yellow-800 p-2 mx-auto w-full max-w-lg pr-12 relative leading-5 text-yellow-700 dark:text-yellow-300 font-medium flex justify-between items-center"
        role="alert"
    >
      <div class="message">
        <span>{{ t('INSTALL_APP_DESC_1') }}</span>
      </div>
      <div class="flex gap-4 mt-4">
        <GMButton primary @click="$pwa.install()">{{ t('INSTALL') }}</GMButton>
        <GMButton @click="$pwa.cancelInstall()">{{ t('CANCEL') }}</GMButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {GMButton, GMIcon} from '@gm/components'
import { useA2HSStore } from '@/use/ui/a2hs'

export default defineComponent({
  name: 'AddToHomeScreen',
  components: {
    GMButton,
    GMIcon
  },
  setup () {
    const { t } = useTranslation()
    const { hideA2HS, isVisibleA2HS } = useA2HSStore()

    const close = (): void => {
      hideA2HS()
    }

    return {
      t,
      close,
      isVisibleA2HS
    }
  }
})
</script>
