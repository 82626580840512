<template>
  <div
    id="app"
    class="min-h-screen w-screen relative bg-gray-50 dark:bg-gray-900 dark:text-gray-300"
    :class="{
      'h-screen overflow-hidden': isSearchPanelOpen
    }"
  >
    <VitePwaManifest />
    <DarkModeListener />
    <GMNotifications
      class="absolute z-40"
      offset-bottom="18"
      offset-top="18"
      offset-right="18"
      offset-left="18"
    />
    <!-- For Tailwind Jit purge to work we need to hardcode the classes here so they're not stripped away -->
    <div class="hidden top-18 right-18 bottom-18 left-18"></div>
    <GMConfirmations class="absolute z-50" />
    <LoadingIndicator />
    <div class="relative z-10">
      <GMAlert v-if="userError">
        Det oppstod en feil ved lasting av brukerkonto: {{ userError }}
      </GMAlert>

      <template v-else>
        <div v-if="lastUpdated === null && $msal.getActiveAccount() != null">
          <Initialize />
        </div>
        <template v-else>
          <AppSidebar />

          <div
            class="transition-all ease-out w-screen min-h-screen view bg-gray-50 dark:bg-gray-900 relative pb-20 flex flex-col"
          >
            <div
              v-show="isSidebarOpen"
              class="fixed top-0 left-0 w-full h-full opacity-50 bg-gray-700 z-30"
              @click="toggleSidebar()"
            ></div>

            <AppHeader class="sticky top-0 z-40" />

            <div class="my-4 h-full flex-1 flex flex-col">
                <NotesPage v-if="isNotesOpen" />
                <slot v-else />
            </div>

            <ClientOnly>
              <AddToHomeScreen />
            </ClientOnly>
          </div>
        </template>
      </template>
    </div>
    <div id="portal-modals" />
  </div>
</template>

<script lang="ts">

import '@gm/components/dist/style.css'
import {GMNotifications, GMConfirmations, confirm, ConfirmationTypes, GMAlert} from '@gm/components'
import { useSidebar } from '@/use/ui/sidebar'
import { useDataStore } from '@/use/data/store'
import { useSearchPanel } from '@/use/ui/searchPanel'
import { useUserStore } from '@/use/user/store'
import {AddToHomeScreen, AppHeader, AppSidebar} from '#components';
import Initialize from '~/components/new/Initialize.vue';
import type {PublicClientApplication} from '@azure/msal-browser';
import NotesPage from '~/components/Notes/NotesPage.vue'
import { useNotes } from '~/use/notes/notes'
import {useDraftsDb} from "~/use/indexedDb/draftsDb";
import {useQueueSizeStore} from "~/use/ui/queueSize";


export default defineComponent({
  name: 'DefaultLayout',
  components: {
    GMAlert,
    NotesPage,
    AppHeader,
    AppSidebar,
    AddToHomeScreen,
    Initialize,
    GMNotifications,
    GMConfirmations
  },
  setup() {
    const { toggleSidebar, isSidebarOpen } = useSidebar()
    const { clearData, lastUpdated, updateDataInBackground, getStorageQuota } = useDataStore()
    const { isSearchPanelOpen } = useSearchPanel()
    const { currentUser, userError } = useUserStore()
    const $msal = useNuxtApp().$msal as PublicClientApplication
    const { draftsDb } = useDraftsDb()
    const { queueSize } = useQueueSizeStore()
    const { t } = useTranslation()

    const { toggleNotes, isNotesOpen, closeNotes } = useNotes()
    const route = useRoute()
    const router = useRouter()

    watch(route, () => {
      if (isNotesOpen.value) {
        closeNotes()
      }
    })

    let loadDataIntervalId
    let checkStorageUseIntervalId

    const signOut = async (): Promise<void> => {
      await clearData()
      $msal.logoutRedirect({
        postLogoutRedirectUri: window.location.origin
      })
    }

    const checkAndHandleStorageUse = () => {
      getStorageQuota().then(async (quota) => {
        if (quota.availableSpace && quota.availableSpace < (1024 * 1024)) { // require at least 1 MB available storage space
          console.warn('Available storage space is low')
          console.log(quota)
          const drafts = await draftsDb.getAll()

          // If no drafts and queue, or user confirms deletion
          // clear data and route to loading
          if (await confirm(
                  t('QUOTA_EXCEEDED_TEXT', {
                    drafts: drafts.length,
                    queue: queueSize.value
                  }) as string,
                  {
                    type: ConfirmationTypes.warning,
                    icon: 'exclamation',
                    options: [
                      {
                        text: t('YES_CLEAN_DATA') as string,
                        value: true,
                        primary: true,
                        iconAfter: 'trash',
                        destructive: true
                      },
                      {
                        text: t('CANCEL') as string,
                        value: false
                      }
                    ]
                  }
              )
          ) {
            await clearData()
            await router.push('/loading')
          }
        }
      })
    }


    onMounted(() => {
      // Update all data in background whenever page is refreshed
      const nowTimestampSeconds = Date.now() / 1000
      const lastUpdatedTimestampSeconds = new Date(lastUpdated.value).getTime() / 1000
      const differenceSeconds = nowTimestampSeconds - lastUpdatedTimestampSeconds

      if (navigator.onLine && differenceSeconds > 3600 /* one hour */) {
        try {
          updateDataInBackground()
        } catch (error) {
          console.log(error)
        }
      }

      if (navigator.onLine) {
        checkAndHandleStorageUse()
      }

      // Some user might never hit the refresh button in their app.
      // force updating every hour
      loadDataIntervalId = setInterval(() => {
        console.log('updating because of interval reached')
        if (navigator.onLine) {
          try {
            updateDataInBackground()
          } catch (error) {
            console.log(error)
          }
        }
      }, 1000 * 60 * 60)

      checkStorageUseIntervalId = setInterval(() => {
        if (navigator.onLine) {
          checkAndHandleStorageUse()
        }
      }, 1000 * 60 * 5)
    })

    onBeforeUnmount(() => {
      clearInterval(loadDataIntervalId)
      clearInterval(checkStorageUseIntervalId)
    })

    return {
      currentUser,
      isSearchPanelOpen,
      isSidebarOpen,
      lastUpdated,
      signOut,
      toggleSidebar,
      userError,
      isNotesOpen,
      toggleNotes,
    }
  },
})
</script>

<style>
.pwa-toast {
  position: fixed;
  right: 0;
  bottom: 0;
  margin: 16px;
  padding: 12px;
  border: 1px solid #8885;
  border-radius: 4px;
  z-index: 1;
  text-align: left;
  box-shadow: 3px 4px 5px 0 #8885;
}
.pwa-toast .message {
  margin-bottom: 8px;
}
.pwa-toast button {
  border: 1px solid #8885;
  outline: none;
  margin-right: 5px;
  border-radius: 2px;
  padding: 3px 10px;
}
</style>
